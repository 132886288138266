import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const ohBaby = require("../images/oh-baby-full.svg")

const IndexPage = () => (
  <Layout>
    <header className="home-header">
      <div className="home-header__image">
        <img src={ohBaby} alt="Oh Baby" />
      </div>
    </header>
    <section className="home-content background">
      <div className="container">
        <h1>
          Celebrating the parents to-be<br></br>Amanda & Drew Templeton
        </h1>
        <div className="home-content__address">
          <h2>
            Sunday, September 22nd
            <br />
            11AM - 2PM
            <br />
            <a href="https://www.google.com/maps/place/2337+N+Milwaukee+Ave,+Chicago,+IL+60647/@41.9238435,-87.7006142,17z/data=!4m13!1m7!3m6!1s0x880fcd62622e512f:0xc5bf657fbc0e9991!2s2337+N+Milwaukee+Ave,+Chicago,+IL+60647!3b1!8m2!3d41.9238435!4d-87.6984202!3m4!1s0x880fcd62622e512f:0xc5bf657fbc0e9991!8m2!3d41.9238435!4d-87.6984202">
              2337 N. Milwaukee Ave.
            </a>
          </h2>
        </div>
      </div>
    </section>
    <section className="rsvp">
      <div className="container">
        <h1>RSVP</h1>
        <p>We'd love to know if you can make it.</p>
        <a href="mailto:drewbie613@gmail.com">Click here to RSVP</a>
      </div>
    </section>
    <section className="registry background">
      <div className="container">
        <h1>Registry</h1>
        <a href="https://www.buybuybaby.com/store/giftregistry/viewregistryguest/547726326">
          Buy Buy Baby
        </a>
        <a href="https://www.potterybarnkids.com/registry/sdm7hhmhw7/registry-list.html">
          Pottery Barn Kids
        </a>
      </div>
    </section>
    {/* <section className="details">
      <div className="container">
        <h1>Event Details</h1>
        <p>Street parking is available on Milwaukee Ave.</p>
      </div>
    </section> */}
  </Layout>
)

export default IndexPage
